import React from 'react';

import SubCategories from '../components/content/our-products/sub-categories';

const SubCategoriesTemplate = ({ pageContext, location }) => {
  return (
    <SubCategories
      subCategories={pageContext.subCategories || []}
      location={location}
    />
  );
};

export default SubCategoriesTemplate;
